import React from "react";
import { useState } from "react";
import Booking from "../../pages/Booking"

export default function Calendar() {
    const [ openState, setOpenState ] = useState({
        isOpen: false
    });
    function showBooking() {
        setOpenState( state => ({
            isOpen: !state.isOpen
        }))
    };

    return (
        <section id="calendar">
            <div class="text-block">
                <h2>Book a Session</h2>
                <p>All in studio sessions with Brittney are fully booked through July, and she has extremely limited zoom availability. However, please email <a href="mailto:brittney@eskewconsulting.com">brittney@eskewconsulting.com</a> with your appointment request and she will get back to you as soon as possible. Some replies may take several days due to her travel schedule.</p>
                <button onClick={()=> showBooking()} className="cta transition">Book a session</button>
            </div>
            <Booking className={openState.isOpen ? "book-now" : ""}/>
            
        </section>
    )
}